import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Button, DatePicker, Input, Radio, Tabs, Checkbox, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useLogin from '../../hooks/useLogin';

// Helper to format date for the URLs
function formatDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

// Data Export URL builder
function useClientVotesUrl({ start, end, clientId }) {
  const baseUrl = `${process.env.REACT_APP_REST_URL}/data-export/clients/${clientId}/votes.xlsx`;
  return useMemo(() => {
    let url = baseUrl;
    if (start || end) url += '?';
    if (start) url += `start=${encodeURIComponent(formatDate(start))}`;
    if (start && end) url += '&';
    if (end) url += `end=${encodeURIComponent(formatDate(end))}`;
    return url;
  }, [baseUrl, start, end]);
}

// Alternate Data Export (Leads-style) URL builder
function useClientVotesAsLeadsUrl({ start, end, clientId }) {
  const baseUrl = `${process.env.REACT_APP_REST_URL}/leads-data-export/clients/${clientId}/leadsdata.xlsx`;
  return useMemo(() => {
    let url = baseUrl;
    if (start || end) url += '?';
    if (start) url += `start=${encodeURIComponent(formatDate(start))}`;
    if (start && end) url += '&';
    if (end) url += `end=${encodeURIComponent(formatDate(end))}`;
    return url;
  }, [baseUrl, start, end]);
}

// Chat Export URL builder
function useClientChatsUrl({ start, end, sheetID, clientId }) {
  const baseUrl = `${process.env.REACT_APP_REST_URL}/chat-export/clients/${clientId}/chats.xlsx`;
  return useMemo(() => {
    let url = baseUrl;
    if (start || end || sheetID) url += '?';
    if (start) url += `start=${encodeURIComponent(formatDate(start))}`;
    if (start && end) url += '&';
    if (end) url += `end=${encodeURIComponent(formatDate(end))}`;
    if (sheetID) url += `&sheet_id=${sheetID}`;
    return url;
  }, [baseUrl, start, end, sheetID]);
}

// Leads Export URL builder
function useClientLeadsUrl({ start, end, clientId }) {
  const baseUrl = `${process.env.REACT_APP_REST_URL}/leads-export/clients/${clientId}/leads.xlsx`;
  return useMemo(() => {
    let url = baseUrl;
    if (start || end) url += '?';
    if (start) url += `start=${encodeURIComponent(formatDate(start))}`;
    if (start && end) url += '&';
    if (end) url += `end=${encodeURIComponent(formatDate(end))}`;
    return url;
  }, [baseUrl, start, end]);
}

function ExportModal({ visible, onClose, defaultActiveTab = 'data' }) {
  const { t } = useTranslation();
  const { user } = useLogin();

  const [activeTabKey, setActiveTabKey] = useState(defaultActiveTab);

  // ===== Data Export =====
  const [dataStartDate, setDataStartDate] = useState(null);
  const [dataEndDate, setDataEndDate] = useState(null);
  const [exportAsLeads, setExportAsLeads] = useState(false);
  const onChangeDataRange = useCallback((dates) => {
    const [start, end] = dates || [];
    setDataStartDate(start);
    setDataEndDate(end);
  }, []);

  // ===== Chat Export =====
  const [chatStartDate, setChatStartDate] = useState(null);
  const [chatEndDate, setChatEndDate] = useState(null);
  const [sheetID, setSheetID] = useState('');
  const onChangeChatRange = useCallback((dates) => {
    const [start, end] = dates || [];
    setChatStartDate(start);
    setChatEndDate(end);
  }, []);

  // ===== Leads Export =====
  const [leadsStartDate, setLeadsStartDate] = useState(null);
  const [leadsEndDate, setLeadsEndDate] = useState(null);
  const onChangeLeadsRange = useCallback((dates) => {
    const [start, end] = dates || [];
    setLeadsStartDate(start);
    setLeadsEndDate(end);
  }, []);

  // ===== URLs =====
  const clientVotesUrl = useClientVotesUrl({
    start: dataStartDate,
    end: dataEndDate,
    clientId: user?.client_id,
  });

  const clientVotesAsLeadsUrl = useClientVotesAsLeadsUrl({
    start: dataStartDate,
    end: dataEndDate,
    clientId: user?.client_id,
  });

  const clientChatsUrl = useClientChatsUrl({
    start: chatStartDate,
    end: chatEndDate,
    sheetID,
    clientId: user?.client_id,
  });

  const clientLeadsUrl = useClientLeadsUrl({
    start: leadsStartDate,
    end: leadsEndDate,
    clientId: user?.client_id,
  });

  const modalTitle = useMemo(() => {
    switch (activeTabKey) {
      case 'leads':
        return t('events.export_leads.title');
      case 'chat':
        return t('events.export_chat.title');
      default:
        return t('events.export_data.title');
    }
  }, [activeTabKey, t]);

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Tabs
        defaultActiveKey={defaultActiveTab}
        onChange={(key) => setActiveTabKey(key)}
      >

        {/* ==================== Data Export Tab ==================== */}
        <Tabs.TabPane key="data" tab={t('common.buttons.export_votes')}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Radio.Group style={{ marginBottom: 10 }}>
              <Radio defaultChecked>{t('events.export_data.body')}</Radio>
            </Radio.Group>
            <Checkbox
              checked={exportAsLeads}
              onChange={(e) => setExportAsLeads(e.target.checked)}
            >
              {t('events.export_data.checkbox_label', 'Export as Leads')}
            </Checkbox>
          </Row>
          <DatePicker.RangePicker onChange={onChangeDataRange} />
          <div style={{ textAlign: 'right' }}>
            <Button type="link" onClick={onClose}>
              {t('common.buttons.close')}
            </Button>
            <Button
              type="primary"
              href={exportAsLeads ? clientVotesAsLeadsUrl : clientVotesUrl}
              target="_blank"
              onClick={onClose}
            >
              {t('common.buttons.export')}
            </Button>
          </div>
        </Tabs.TabPane>

        {/* ==================== Chat Export Tab ==================== */}
        <Tabs.TabPane key="chat" tab={t('common.buttons.export_chat')}>
          <Radio.Group style={{ marginBottom: 10 }}>
            <Radio defaultChecked>{t('events.export_chat.body')}</Radio>
          </Radio.Group>
          <DatePicker.RangePicker
            onChange={onChangeChatRange}
            style={{ display: 'block', marginBottom: 16 }}
          />
          <Input
            placeholder={t('events.export_chat.sheet_ID')}
            value={sheetID}
            onChange={(e) => setSheetID(e.target.value)}
            style={{ marginBottom: 16 }}
          />
          <div style={{ textAlign: 'right' }}>
            <Button type="link" onClick={onClose}>
              {t('common.buttons.close')}
            </Button>
            <Button
              type="primary"
              href={clientChatsUrl}
              target="_blank"
              onClick={onClose}
            >
              {t('common.buttons.download_csv')}
            </Button>
          </div>
        </Tabs.TabPane>

        {/* ==================== Leads Export Tab ==================== */}
        <Tabs.TabPane key="leads" tab={t('common.buttons.export_leads')}>
          <Radio.Group style={{ marginBottom: 10 }}>
            <Radio defaultChecked>{t('events.export_leads.body')}</Radio>
          </Radio.Group>
          <DatePicker.RangePicker
            onChange={onChangeLeadsRange}
            style={{ marginBottom: 16 }}
          />
          <div style={{ textAlign: 'right' }}>
            <Button type="link" onClick={onClose}>
              {t('common.buttons.close')}
            </Button>
            <Button
              type="primary"
              href={clientLeadsUrl}
              target="_blank"
              onClick={onClose}
            >
              {t('common.buttons.export')}
            </Button>
          </div>
        </Tabs.TabPane>

      </Tabs>
    </Modal>
  );
}

export default ExportModal;
